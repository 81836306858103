jQuery(document).ready(function () {
  if (jQuery(window).width() > 768) {
    function setEqualHeight() {
      var maxHeight = 0;

      // Reset heights before recalculating
      jQuery('.t-entry-text').css('height', 'auto');

      // Loop through all cards to find the tallest one
      jQuery('.t-entry-text').each(function () {
        var thisHeight = jQuery(this).outerHeight();
        if (thisHeight > maxHeight) {
          maxHeight = thisHeight;
        }
        // alert(maxHeight);
      });

      // Set all card heights to the tallest height
      jQuery('.t-entry-text').css('height', maxHeight + 'px');
    }

    // Run the function when the page loads
    setEqualHeight();

    // Recalculate on window resize (optional)
    jQuery(window).resize(function () {
      setEqualHeight();
    });
  }
  // Select the div with the class "latest-news-grid"

  if (jQuery('body').hasClass('home')) {
    jQuery('.latest-news-grid a').each(function () {
      jQuery(this).attr('target', '_blank');
    });
  }
 
});
